import { BrowserRouter as Router } from 'react-router-dom'
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { AuthProvider } from './auth-context'

const queryClient = new QueryClient()

export const AppProviders = ({ children }) => (
  <QueryClientProvider client={queryClient}>
    <Router>
      <AuthProvider>{children}</AuthProvider>
    </Router>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)
