import React from 'react'

export const useColorScheme = () => {
  const darkQuery = window.matchMedia('(prefers-color-scheme: dark)')
  const [isDark, setIsDark] = React.useState(darkQuery.matches)
  React.useEffect(() => {
    const handler = () => setIsDark(darkQuery.matches)
    darkQuery.addEventListener('change', handler)
    return () => darkQuery.removeEventListener('change', handler)
  })
  return { isDark }
}

export const useLoginForm = () => {
  return React.useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    { username: '', password: '' }
  )
}

export const useNewPasswordForm = () => {
  return React.useReducer(
    (prevState, newState) => ({ ...prevState, ...newState }),
    { newPassword: '', name: '' }
  )
}

export const USDollarFormatter = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
})

export const getMonth = (offset = 0) => {
  const date = new Date()
  date.setMonth(date.getMonth() + offset)
  return date.toISOString().slice(0, 7)
}

export const getDate = ({ monthOffset }) => {
  const date = new Date()
  date.setMonth(date.getMonth() + monthOffset)
  return date
}

export const getToday = () => {
  return new Date()
}

export const transition = (state, event, transitions) => {
  return transitions[state.state][event.type]
    ? transitions[state.state][event.type](event, state)
    : state
}

export const exec = (state, effects) =>
  effects[state.state] && effects[state.state](state)
