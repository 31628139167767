/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:ba8910b9-55e9-4fd6-90f3-87d7e059a31f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_f4Qf3PbuZ",
    "aws_user_pools_web_client_id": "2c18g1h4r3p4b95qfrekfkg86m",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://i6gmcecs2fcjrixkcjqdkw2czm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-3usm7micgjenrnqkxwhxjh27ku"
};


export default awsmobile;
