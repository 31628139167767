import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAuth } from '../context/auth-context'
import portraitPlaceholder from '../images/portrait-placeholder.png'
import {
  FaSpinner,
  FaHome,
  FaFileContract,
  FaFileImage,
  FaBars,
  FaPlay,
} from 'react-icons/fa'
import { GrClose } from 'react-icons/gr'

const StyledNavLink = ({ to, icon, indent, children, exact }) => (
  <NavLink
    to={to}
    style={{ marginLeft: indent ? `${indent}rem` : '' }}
    className="flex flex-row space-x-1 text-2xl py-2"
    activeClassName="text-main"
    exact={exact}
  >
    <div className="flex flex-col justify-center px-2">{icon}</div>
    <p className="flex-none">{children}</p>
  </NavLink>
)

const Nav = ({ isOpen, toggleNav }) => (
  <nav
    className="h-full fixed z-10 overflow-x-hidden bg-white top-0 border-gray-300"
    style={{
      width: isOpen ? '18rem' : 0,
      borderRightWidth: isOpen ? '1px' : '',
      transition: '0.5s',
    }}
  >
    <div className="text-right text-gray-800">
      <button onClick={toggleNav} className="focus:outline-none">
        <GrClose size={60} className="inline p-3" />
      </button>
    </div>
    <StyledNavLink to="/" icon={<FaHome />} exact>
      Dashboard
    </StyledNavLink>
    <StyledNavLink to="/clinic" icon={<FaFileContract />}>
      Clinic
    </StyledNavLink>
    <StyledNavLink to="/video" icon={<FaFileImage />}>
      Training
    </StyledNavLink>
    <StyledNavLink to="/video/test_training" icon={<FaPlay />} indent={1.5}>
      Test Training
    </StyledNavLink>
    <StyledNavLink to="/video/mixing_training" icon={<FaPlay />} indent={1.5}>
      Mixing Training
    </StyledNavLink>
    <StyledNavLink to="/video/unboxing" icon={<FaPlay />} indent={1.5}>
      Unboxing a Tray
    </StyledNavLink>
    <StyledNavLink to="/video/app_testing" icon={<FaPlay />} indent={1.5}>
      App Testing
    </StyledNavLink>
  </nav>
)

function Header({ toggleNav }) {
  const {
    state: { user },
    signOut,
  } = useAuth()
  return (
    <div className="flex flex-row justify-between text-right shadow-md">
      <button
        onClick={toggleNav}
        className="flex flex-col justify-center px-4 bg-gray-600 focus:outline-none"
      >
        <FaBars size={60} className="text-white" />
      </button>
      <div className="inline-block mt-3 mb-2">
        <div className="grid grid-rows-3 grid-flow-col items-center">
          <div className="text-2xl">{user.attributes.name}</div>
          <div className="text-sm">Account Settings</div>
          <div role="button" onClick={signOut}>
            Sign Out
          </div>
          <img
            className="h-24 row-span-3 mx-3"
            src={portraitPlaceholder}
            alt="Portrait Placeholder"
          />
        </div>
      </div>
    </div>
  )
}

export function Layout({ children }) {
  const [navOpen, setNavOpen] = React.useState(false)
  const toggleNav = () => setNavOpen((open) => !open)
  return (
    <div>
      <Header toggleNav={toggleNav} />
      <Nav isOpen={navOpen} toggleNav={toggleNav} />
      <main className="py-8 mx-8 md:mx-32">{children}</main>
    </div>
  )
}

const FullPage = ({ children, className, ...props }) => (
  <div
    className={`flex flex-col items-center justify-center w-full h-screen ${className}`}
    {...props}
  >
    {children}
  </div>
)

export const FullPageErrorFallback = ({ error }) => (
  <FullPage role="alert">
    <p>Uh oh... There's a problem. Try refreshing the app.</p>
    <pre>{error.message}</pre>
  </FullPage>
)

export const FormGroup = ({ children }) => (
  <div className="flex flex-col m-3">{children}</div>
)

export const Input = (props) => (
  <input
    className="border-solid border-2 border-gray-300 bg-white-800 rounded-md p-2 mt-2"
    {...props}
  />
)

export const Spinner = ({ className }) => (
  <FaSpinner className={`animate-spin ${className}`} />
)

export const FullPageSpinner = () => (
  <FullPage className="text-7xl text-main">
    <Spinner />
  </FullPage>
)

export const Box = ({ children }) => (
  <div className="inline-block border-2 border-gray-200 p-4">{children}</div>
)

export const DataBox = ({ title, value, format }) => (
  <Box>
    <div className="grid grid-rows-2 grid-flow-row">
      <div className="font-bold">{title.toUpperCase()}</div>
      <div>{format ? format(value) : value}</div>
    </div>
  </Box>
)
