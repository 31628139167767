import React from 'react'
import { Auth } from 'aws-amplify'
import { FullPageSpinner } from '../components/lib'
import { signInReducer } from '../reducers/login'
import useAsync from 'use-async-machine'

const AuthContext = React.createContext()

const useSignIn = () => {
  const [signInState, dispatch] = React.useReducer(signInReducer, {
    state: 'signedOut',
  })

  const signIn = (username, password) =>
    Auth.signIn(username, password).then((user) => {
      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        dispatch({ type: 'requestNewPassword', context: user })
      } else {
        dispatch({ type: 'signInSuccess', user })
      }
    })

  const completeNewPassword = (newPassword, requiredAttributes) => {
    const { context: user } = signInState
    return Auth.completeNewPassword(
      user,
      newPassword,
      requiredAttributes
    ).then(() =>
      Auth.currentAuthenticatedUser().then((user) =>
        dispatch({ type: 'newPasswordSuccess', user })
      )
    )
  }

  const signOut = () =>
    Auth.signOut().then(() => dispatch({ type: 'signOutSuccess' }))

  return { signIn, signOut, completeNewPassword, signInState, dispatch }
}

export const AuthProvider = (props) => {
  const { signInState, dispatch, ...signInActions } = useSignIn()
  const { dispatch: dispatchUser, isLoading } = useAsync(
    () => Auth.currentAuthenticatedUser(),
    {
      onSuccess: ({ data: user }) => {
        dispatchUser({ type: 'reset' })
        dispatch({ type: 'signInSuccess', user })
      },
    }
  )

  React.useEffect(() => {
    dispatchUser({ type: 'load' })
  }, [dispatchUser])

  const value = {
    state: signInState,
    ...signInActions,
  }

  if (isLoading) {
    return <FullPageSpinner />
  }

  return <AuthContext.Provider value={value} {...props} />
}

export const useAuth = () => {
  const context = React.useContext(AuthContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within an AuthProvider`)
  }
  return context
}
